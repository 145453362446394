import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Col, Row } from "antd";
import logo from "../../images/TRA.PNG";
import Gallery from "./Gallery";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "../../styles/Home.css";

const services = [
  "New Construction Electrical Planning and Installation",
  "Electrical Troubleshooting and Diagnosis",
  "Light Fixture and Ceiling Fan Installation",
  "Appliance Wiring and Connection",
  "Outlet and Switch Replacement/Upgrades",
  "Remodeling Electrical Services",
  "Service Panel Upgrades and Replacements",
  "Landscape Lighting Design and Installation",
];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let logoStyle = {};
  if (width < 600) {
    logoStyle = { width: "30%", marginBottom: '1rem' };
  } else {
    logoStyle = { width: "15%", marginBottom: '1rem' };
  }

  let bannerStyle = {
    backgroundColor: "lightgray",
    borderRadius: ".5rem",
    padding: ".2rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    color: "#0B0346",
    marginLeft: "-3rem",
  };
  return (
    <>
      <ScrollToTopOnMount />
      <Row justify="center">
        <img style={logoStyle} src={logo} alt="TRA Electric Logo" />
      </Row>

      <div style={{ marginLeft: "-5rem" }}>
        <Row>
          <Col offset={7}>
            <h1 className="shadow" style={bannerStyle}>
              Safety
            </h1>
          </Col>
        </Row>
        <Row>
          <Col offset={11}>
            <h1 className="shadow" style={bannerStyle}>
              Quality
            </h1>
          </Col>
        </Row>
        <Row>
          <Col offset={15}>
            <h1 className="shadow" style={bannerStyle}>
              Precision
            </h1>
          </Col>
        </Row>
      </div>

      <Row
        className="shadow-lg"
        style={{
          backgroundColor: "lightgray",
          marginTop: "2.5em",
          textAlign: "center",
          marginBottom: "2.5em",
          paddingTop: ".9rem",
        }}
      >
        <h1
          style={{
            color: "#0B0346",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "",
          }}
        >
          Word of Mouth is a Force of Nature
        </h1>
      </Row>
      <Row justify="center">
        <Grid container spacing={2}>
          <Grid
            style={{ marginLeft: "auto", marginRight: "auto" }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Col style={{}} span={24}>
              <div
                className="summaryCard"
                style={{}}
              >
                <h3 style={{ color: "#0B0346" }}>
                  At TRA Electric, we believe that word of mouth is the most powerful form of advertising. That's why we are dedicated to providing exceptional service to our clients. From the smallest task, such as changing a light fixture, to a comprehensive home rewire, we ensure that every project is given the attention and care it deserves.
                  <br />{" "}
                </h3>
              </div>
            </Col>
          </Grid>
        </Grid>
      </Row>
      <Row
        className="shadow-lg"
        style={{
          backgroundColor: "lightgray",
          marginTop: "2.5em",
          textAlign: "center",
          marginBottom: "2.5em",
        }}
      >
        <h1
          style={{
            color: "#0B0346",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: ".5em",
          }}
        >
          Services Offered
        </h1>
      </Row>
      <Row>
        <div
          className="site-card-wrapper"
          style={{ margin: "auto", textAlign: "center" }}
        >
          <Row gutter={3} className="serviceCard">
            <div className="serviceDiv">
              <div className="smallLandingCard" bordered={false}>
                {services.map((service) => (
                  <>
                    <h3 className="smallLandingCard">{service}</h3>
                  </>
                ))}
              </div>
            </div>
          </Row>
        </div>
      </Row>
      <Row justify="center">
        <Col style={{ marginBottom: "2.5rem" }}>
          <Link to="/requestquote">
            <h1 className="shadow-lg requestQuote">Request A Quote</h1>
          </Link>
        </Col>
      </Row>
      <Row
        className="shadow-lg"
        style={{
          backgroundColor: "lightgray",
          textAlign: "center",
          marginBottom: "2.5em",
        }}
      >
        <h1
          style={{
            color: "#0B0346",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: ".5em",
          }}
        >
          Completed Projects
        </h1>
      </Row>
      <Row justify="center" style={{ marginTop: "5em", marginBottom: "2.5em" }}>
        <Col span={24}>
          <Gallery />
        </Col>
      </Row>

      <Row

        style={{
          // marginBottom: "5em",
          display: 'flex',
          justifyContent: 'center',
          marginTop: "4em",
          maxWidth: "100%",
          textAlign: "center",
          backgroundColor: "lightgray",
          color: "#0B0346",
        }}
      >
        <Col span={12} style={{ marginTop: 'auto', marginBottom: 'auto', paddingTop: '2rem' }}>
          <p>TRA Electric: <br/> 512-409-8958 </p>
          <p>Licensed and Insured: <br/> TECL-37736</p>
        </Col>
        <Col style={{ margin: 'auto', }}>
          <img className='footerLogo' src={logo} alt="TRA Electric Logo" />
          <p>Se habla español</p>
        </Col>

      </Row>

      <Outlet />
    </>
  );
}

export default Home;
