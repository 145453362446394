
import image4 from '../../images/4.jpeg';
import image5 from '../../images/5.jpeg';
import image6 from '../../images/6.jpeg';
import image7 from '../../images/7.jpeg';
import image8 from '../../images/8.jpeg';
import image9 from '../../images/9.jpeg';
import image10 from '../../images/10.jpeg';
import image11 from '../../images/11.jpeg';
import image12 from '../../images/12.jpeg';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Carousel from "better-react-carousel";
import { useState, useEffect } from 'react';

export default function Gallery() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let imageStyle;
    if (width < 600) {
        imageStyle = { maxWidth: '100%', maxHeight: '60%', }
    } else {
        imageStyle = { maxWidth: '100%', maxHeight: '70%', marginTop: 'auto', }
    }
    return (
        <Grid container spacing={2} style={{ maxHeight: '100%' }}>
            <Grid style={{marginBottom: '-10rem', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', justifyContent: 'center', display: 'flex' }} xs={12} sm={12} md={12} lg={12}>

                <Carousel
                    cols={1}
                    rows={1}
                    gap={15}
                    loop
                    autoplay={21000}
                    showDots={false}
                    style={{marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', justifyContent: 'center', display: 'flex' }}
                >

                    <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image4} />
                    </Carousel.Item>
                    <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image5} />
                    </Carousel.Item>
                    {/* <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image6} />
                    </Carousel.Item>
                    <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image7} />
                    </Carousel.Item> */}
                    {/* <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image8} />
                    </Carousel.Item> */}
                    <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image9} />
                    </Carousel.Item>
                    <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image10} />
                    </Carousel.Item>
                    <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image11} />
                    </Carousel.Item>
                    <Carousel.Item className="shadow">
                        <img className="shadow" style={imageStyle} src={image12} />
                    </Carousel.Item >

                </Carousel>

            </Grid>

        </Grid>
    );
}
const itemData = [

    image4,


    image5,
    image6,

    image7,

    image8,
    image9,

    image10,

    image11,

    image12,


];
