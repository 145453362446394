import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/routes/Home';
import RequestQuote from './components/routes/RequestQuote';



function App() {
  return (
    <>
    <BrowserRouter  >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Requestquote" element={<RequestQuote />} />
      </Routes>
    </BrowserRouter>
   
  </>
  );
}

export default App;
